<template>
  <S2SForm :title="`Connection Stats`">
    <v-card class="pa-5">
      <QueryRenderer :cubejs-api="cubejsApi" :query="queryCustomerStats">
        <template v-slot="{ loading, resultSet }">
          <v-skeleton-loader v-if="loading" type="image"></v-skeleton-loader>
          <line-chart
            title="Payment Status"
            v-else-if="resultSet.loadResponse.results[0].data.length"
            legend="bottom"
            prefix="R"
            :data="series(resultSet)"
            round="2"
            zeros="true"
            thousands=" "
          ></line-chart>
          <v-flex v-else class="text-center pa-5 echarts">
            No graph data available
            <v-skeleton-loader type="image"></v-skeleton-loader>
          </v-flex>
        </template>
      </QueryRenderer>

      <v-row>
        <v-col cols="12">
          <v-card class="pa-5" tile>
            <div class="title ml-7 mb-5">Items sold in the last 3 months</div>
            <column-chart
              v-if="!loadingData"
              :class="!threeMonthData.length ? 'chart no-data' : 'chart'"
              legend="bottom"
              :colors="[
                '#FAA225',
                '#e6194B',
                '#3cb44b',
                '#ffe119',
                '#4363d8',
                '#f58231',
                '#911eb4',
                '#42d4f4',
                '#f032e6',
                '#bfef45',
                '#fabed4',
                '#469990',
                '#dcbeff',
                '#9A6324',
                '#fffac8',
                '#800000',
                '#aaffc3',
                '#808000',
                '#ffd8b1',
                '#000075',
                '#a9a9a9',
                '#ffffff',
                '#000000',
              ]"
              :data="threeMonthData"
              :messages="{ empty: 'No data available' }"
            ></column-chart>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card class="pa-5" tile>
            <div class="title ml-7 mb-5">Items sold this year per month</div>
            <column-chart
              v-if="!loadingData"
              :class="!breakdownData.length ? 'chart no-data' : 'chart'"
              legend="bottom"
              :colors="[
                '#FAA225',
                '#e6194B',
                '#3cb44b',
                '#ffe119',
                '#4363d8',
                '#f58231',
                '#911eb4',
                '#42d4f4',
                '#f032e6',
                '#bfef45',
                '#fabed4',
                '#469990',
                '#dcbeff',
                '#9A6324',
                '#fffac8',
                '#800000',
                '#aaffc3',
                '#808000',
                '#ffd8b1',
                '#000075',
                '#a9a9a9',
                '#ffffff',
                '#000000',
              ]"
              :data="breakdownData"
              :stacked="true"
              :messages="{ empty: 'No data available' }"
            ></column-chart>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </S2SForm>
</template>

<script>
  import cubejs from "@cubejs-client/core";
  import jwt from "jsonwebtoken";
  import { QueryRenderer } from "@cubejs-client/vue";

  export default {
    components: {
      QueryRenderer,
    },

    props: {
      customerId: {
        type: Number,
        default: 0,
      },
    },

    mounted: async function () {
      this.filterCubeQuery();
    },

    watch: {
      customerId: function (newVal, oldVal) {
        this.filterCubeQuery();
      },
    },

    data: function () {
      const cubejsApiToken = jwt.sign(
        { supplierId: this.$store.state.shops.activeShop.id },
        process.env.VUE_APP_CUBEJS_API_SECRET,
        {
          expiresIn: "30d",
        }
      );
      //  Our Cube.js Key and API URL
      const cubejsApi = cubejs(cubejsApiToken, {
        apiUrl: `${process.env.VUE_APP_CUBEJS_API_URL}/cubejs-api/v1`,
      });

      const threeMonthData = null;
      const breakdownData = null;
      const loadingData = false;
      return {
        cubejsApi,
        threeMonthData,
        breakdownData,
        loadingData,
        // Defining Cube.js querys
        queryCustomerStats: {
          measures: ["Orders.lineItemTotal"],
          dimensions: ["Orders.paymentStatus"],
          timeDimensions: [
            {
              dimension: "Orders.createdDateTime",
              granularity: "month",
              dateRange: "This year",
            },
          ],
          filters: [
            {
              member: "Customers.id",
              operator: "equals",
              values: [`${this.$props.customerId}`],
            },
          ],
          timezone: "Africa/Johannesburg",
        },
      };
    },
    methods: {
      series: function (resultSet) {
        const seriesNames = resultSet.seriesNames();
        const pivot = resultSet.chartPivot();
        const series = [];
        const months = {
          "01": "Jan",
          "02": "Feb",
          "03": "Mar",
          "04": "Apr",
          "05": "May",
          "06": "Jun",
          "07": "Jul",
          "08": "Aug",
          "09": "Sep",
          10: "Oct",
          11: "Nov",
          12: "Dec",
        };
        if (seriesNames) {
          seriesNames.forEach((e) => {
            const data = pivot.map((p) => [
              months[p.x.slice(5, 7)],
              Math.round((p[e.key] + Number.EPSILON) * 100) / 100,
            ]);
            if (e.title === "unpaid, Order Line Item Total") {
              series.push({ name: "Unpaid", data });
            } else {
              series.push({ name: "Paid", data });
            }
          });
          return series;
        }
      },
      queryItemsSold: async function (customer, timeDimensions, order) {
        const measures = ["LineItems.quantity"];
        const dimensions = [
          "LineItems.productName",
          "LineItems.productShortDescription",
        ];
        const filters = [
          {
            member: "Customers.id",
            operator: "equals",
            values: [`${customer}`],
          },
        ];
        const limit = 100;

        return {
          measures,
          timeDimensions,
          filters,
          dimensions,
          limit,
          order,
        };
      },
      seriesLastThreeMonths: function (resultSet) {
        const seriesNames = resultSet.seriesNames();
        const pivot = resultSet.chartPivot();
        const series = [];
        if (seriesNames) {
          seriesNames.forEach((e) => {
            const data = pivot.map((p) => [p.x, p[e.key]]);
            series.push({ name: "Quantity", data });
          });
          this.threeMonthData = series;
        }
      },
      seriesPerMonth: function (resultSet) {
        const seriesNames = resultSet.seriesNames();
        const pivot = resultSet.chartPivot();
        const series = [];
        const months = {
          "01": "Jan",
          "02": "Feb",
          "03": "Mar",
          "04": "Apr",
          "05": "May",
          "06": "Jun",
          "07": "Jul",
          "08": "Aug",
          "09": "Sep",
          10: "Oct",
          11: "Nov",
          12: "Dec",
        };
        if (seriesNames) {
          seriesNames.forEach((e) => {
            const data = pivot.map((p) => [months[p.x.slice(5, 7)], p[e.key]]);
            series.push({
              name: e.title.split(", Line Items Quantity")[0],
              data,
            });
          });
          this.loadingData = false;
          this.breakdownData = series;
        }
      },
      loadCubeQuery: async function (query) {
        return await this.cubejsApi.load(query);
      },
      filterCubeQuery: async function () {
        this.loadingData = true;
        const customer = this.$props.customerId;

        // Items sold in the last 3 months
        const timeDimensions1 = [
          {
            dimension: "LineItems.orderPaymentDateTime",
            dateRange: "Last 3 months",
          },
        ];
        const order1 = { "LineItems.quantity": "desc" };

        const query1 = await this.queryItemsSold(
          customer,
          timeDimensions1,
          order1
        );
        const resultSet1 = await this.loadCubeQuery(query1);
        this.seriesLastThreeMonths(resultSet1);

        // Items sold in breakdown per month
        const timeDimensions2 = [
          {
            dimension: "LineItems.orderPaymentDateTime",
            granularity: "month",
            dateRange: "This year",
          },
        ];
        const order2 = {
          "LineItems.orderPaymentDateTime": "asc",
          "LineItems.quantity": "desc",
        };

        const query2 = await this.queryItemsSold(
          customer,
          timeDimensions2,
          order2
        );
        const resultSet2 = await this.loadCubeQuery(query2);
        this.seriesPerMonth(resultSet2);
      },
    },
  };
</script>
