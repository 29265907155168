<template>
  <S2SForm :title="`My Customer: ${connectionSettings.name}`">
    <v-tooltip left slot="toolbar-content">
      <template v-slot:activator="{ on }">
        <v-btn rounded color="accent" v-on="on" @click="orderFor()">
          <v-icon>mdi-cart</v-icon>
        </v-btn>
      </template>
      <span>Create order for {{ connectionSettings.name }}</span>
    </v-tooltip>

    <v-card>
      <v-card-title class="text-center justify-center py-6">
        <div class="font-weight-bold display-1">
          {{ connectionSettings.name }}
          <small class="d-inline-flex v-subheader font-italic"
            >( {{ connectionSettings.types }} )</small
          >
        </div>
      </v-card-title>

      <v-tabs v-model="activeTab" background-color="transparent" grow>
        <v-tab v-for="item in tabs" :key="item.id">{{ item.name }}</v-tab>
      </v-tabs>

      <v-tabs-items v-model="activeTab">
        <v-tab-item v-for="item in tabs" :key="item.id">
          <v-card text>
            <discounts
              v-if="item.id === 'discount'"
              :connectionId="connectionId"
            ></discounts>
            <settings
              v-if="item.id === 'settings'"
              :connectionId="connectionId"
              :customerCode="connectionSettings.code"
            ></settings>
            <conn-stats
              v-if="item.id === 'stats'"
              :connectionId="connectionId"
              :customerId="connectionSettings.id"
            ></conn-stats>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </S2SForm>
</template>

<script>
  import Vue from "vue";
  import { Shop } from "@/store/shops/types";

  import discounts from "@/views/customers/CustomerDiscount.vue";
  import settings from "@/views/customers/CustomerSettings.vue";
  import connStats from "@/views/customers/CustomerStats.vue";

  export default Vue.extend({
    name: "MyCustomerOverview",

    components: { discounts, settings, connStats },

    props: {
      connectionId: {
        type: String,
        default: "0",
      },
      setActiveTab: {
        // Route set from dashboard
        type: String,
        default: "0",
      },
    },

    computed: {
      connectionSettings: function () {
        return this.$store.getters["connections/connectionSettings"];
      },
    },

    methods: {
      orderFor() {
        this.$router
          .push({
            name: "customer-order",
            params: { connectionId: this.$props.connectionId },
          })
          .catch((err) => {});
      },
    },

    data: function () {
      return {
        activeTab: 0,
        tabs: [
          { id: "discount", name: "Discounts" },
          { id: "settings", name: "Connection Settings" },
          { id: "stats", name: "Connection Stats" },
        ],
      };
    },

    mounted: function () {
      this.activeTab = parseInt(this.$props.setActiveTab);
      this.$store.dispatch(
        "connections/fetchConnectionByConnectionId",
        +this.$props.connectionId
      );
      this.$store.dispatch(
        "connections/fetchConnectionSettingsByConnectionId",
        +this.$props.connectionId
      );
    },
  });
</script>
